import { useMemo, useState } from 'react';
import { Button, Grid } from '@mui/material';

import PATHS from '@routes/paths';
import useOrg from '@hooks/useOrg';
import useNavigate from '@hooks/useNavigate';
import { PageOrientation, PageSize } from '@typedefs/app';

import { useContext } from '@pages/receipts/List';
import Dialog from '@components/Dialog';
import { PdfTableData } from '@components/pdf';
import { prepareReceiptTableData } from '@utils/receiptTableData';
import ReportViewer from '@pages/reports/ReportViewer';
import ReportLayout from '@pages/reports/ReportLayout';
import PdfCsvMenu from '@components/pdf/PdfCsvMenu';
import * as analytics from '@fire/analytics';

// ----------------------------------------------------------------------
export default function Export() {
  const { columns, data, dateFrom, dateTo, orderBy } = useContext();
  const navigate = useNavigate();
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const { org } = useOrg();
  const [orientation, setOrientation] = useState<PageOrientation>('portrait');
  const [size, setSize] = useState<PageSize>('letter');
  const [pdfBlob, setBlob] = useState<Blob>();

  const tableData: PdfTableData = useMemo(() => prepareReceiptTableData(columns, data), [columns, data]);

  // --------------------------------------------------
  const handleClose = () => {
    navigate(PATHS.org.receipts.root);
  };

  const handleOpenMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenMenuActions(e.currentTarget);
  };

  const handleComplete = (pdfBlob: Blob) => {
    setBlob(pdfBlob);
  };

  const handleLoading = (isLoading: boolean) => {
    isLoading && setBlob(undefined);
  };

  return (
    <Dialog
      title="Receipts export"
      maxWidth="lg"
      sx={{ '& .MuiPaper-root': { height: '65vh' } }}
      onClose={handleClose}
      actions={
        <Button
          size="large"
          variant="contained"
          onClick={handleOpenMenu}
          disabled={!pdfBlob}
        >
          Download
        </Button>
      }
    >
      <PdfCsvMenu
        tableData={tableData}
        pdfBlob={pdfBlob}
        basename={'export_receipts'}
        openMenu={openMenu}
        setOpenMenu={setOpenMenuActions}
        handleClose={handleClose}
        analyticsPdfFn={() => analytics.donation.exportPDF()}
        analyticsCsvFn={() => analytics.donation.exportCSV()}
      />
      <Grid container spacing={3} alignItems="flex-start" justifyContent="center" height="50vh">
        <Grid item xs={12} md={3}>
          <ReportLayout
            orientation={orientation}
            setOrientation={setOrientation}
            size={size}
            setSize={setSize}
          />
        </Grid>

        <Grid item xs={12} md={9} height="50vh" display="flex">
          <ReportViewer
            onComplete={handleComplete}
            onLoading={handleLoading}
            documentProps={{
              title: 'Receipts export',
              description: 'desc',
              dateReport: new Date(),
              dateFrom: dateFrom,
              dateTo: dateTo,
              orderBy: orderBy,
              orgName: org!.name,
              data: tableData,
              orientation: orientation,
              size: PageSize[size],
            }}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
}