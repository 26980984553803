import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { Category, PaymentMethod } from '@shared/types';
import { TReceiptRenderGroup, TReceiptVariables } from '@typedefs/donation';

import ReceiptHeader from '../components/Header';
import ReceiptDonorInfo from '../components/DonorInfo';
import ReceiptSignature from '../components/Signature';
import ReceiptDonationsTable from '../components/DonationsTable';

import ReceiptBody from './Body';
import ReceiptBodyInfo from './BodyInfo';
import ReceiptInfo from './ReceiptInfo';
import ReceiptFootnote from './Footnote';
import { ReceiptOptions } from '@/schemas';
import ReceiptSummaryTable from '../components/SummaryTable';

const styles = StyleSheet.create({
  body: { display: 'flex', flexDirection: 'row' },
  bodyLeft: { flex: '1 1 auto', display: 'flex', rowGap: 16 },
  bodyRight: { width: '38%', display: 'flex', rowGap: 32 },
  dividerDetach: {
    width: '100%',
    borderBottom: '1px dashed #000',
    marginBottom: 4,
  },
});

// ----------------------------------------------------------------------
type Props = {
  vars: TReceiptVariables;
  group: TReceiptRenderGroup;
  categories: Category.Category[];
  paymentMethods: PaymentMethod.PaymentMethodsWithNameAndType[];
  options: ReceiptOptions;
  year: number;
};
// ----------------------------------------------------------------------
export default function ReceiptCA({ vars, group, categories, paymentMethods, options, year }: Props) {
  const { groupVars } = group;

  return (
    <View>
      {/* -------------------------------------------------- */}
      {/* REQUIRED PART */}
      <ReceiptHeader vars={vars} />

      <View style={styles.body}>
        <View style={styles.bodyLeft}>
          <ReceiptBody groupVars={groupVars} />

          <ReceiptDonorInfo vars={vars} />
          <ReceiptBodyInfo vars={vars} groupVars={groupVars} />
        </View>

        <View style={styles.bodyRight}>
          <ReceiptInfo
            groupVars={groupVars}
            year={year}
          />
          <ReceiptSignature vars={vars} />
        </View>
      </View>

      <ReceiptFootnote
        vars={vars}
        groupVars={groupVars}
        year={year}
        type={group.type}
      />

      {/* -------------------------------------------------- */}
      <View
        style={{
          textAlign: 'center',
          fontFamily: 'Helvetica-Oblique',
          marginTop: 24,
          marginBottom: 32,
        }}
      >
        <View style={styles.dividerDetach} />
        <Text>detach and retain for your records</Text>
      </View>
      {/* -------------------------------------------------- */}

      {/* DETACHED INFO */}
      <View style={styles.body}>
        <View style={styles.bodyLeft}>
          <View>
            <Text style={{ fontFamily: 'Helvetica-Bold' }}>{vars['org.name']}</Text>
            <Text>
              {vars['org.address'].address1} {vars['org.address'].address2}{' '}
              {vars['org.address'].city} {vars['org.address'].state}{' '}
              {vars['org.address'].postalCode}
            </Text>
            {/* TODO: receiptType */}
          </View>

          <ReceiptDonorInfo vars={vars} />
        </View>

        <View style={styles.bodyRight}>
          <ReceiptInfo
            groupVars={groupVars}
            year={year}
            />
        </View>
      </View>

      {options.includeDetails ? (
        <ReceiptDonationsTable
          vars={vars}
          donations={group.donations}
          categories={categories}
          paymentMethods={paymentMethods}
          showDescription={options.showDescription}
        />
      ) : null}
      {options.includeSummary ? (
        <ReceiptSummaryTable
          donations={group.donations}
          categories={categories}
        />
      ) : null}
    </View>
  );
}
