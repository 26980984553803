import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { TRenderGroupVariables } from '@typedefs/donation';
import { formatCurrency } from '@utils/numeralFormat';

const styles = StyleSheet.create({
  row: { display: 'flex', flexDirection: 'row' },
});

// ----------------------------------------------------------------------
type Props = {
  readonly groupVars: TRenderGroupVariables;
};
// ----------------------------------------------------------------------
export default function ReceiptBody({
  groupVars
}: Props) {
  const { advantageTotal, receiptTotal, receiptEligible } = groupVars;

  return (
    <View>
      {!!advantageTotal ? (
        <>
          <View style={styles.row}>
            <Text style={{ marginRight: 4, width: 210 }}>
              Total amount of cash received by charity:
            </Text>
            <Text style={{ marginTop: -2 }}>{formatCurrency(receiptTotal)}</Text>
          </View>

          <View style={styles.row}>
            <Text style={{ marginRight: 4, width: 210 }}>Value of advantage:</Text>
            <Text style={{ marginTop: -2 }}>{formatCurrency(advantageTotal)}</Text>
          </View>
        </>
      ) : null}

      <View style={styles.row}>
        <Text style={{ fontFamily: 'Helvetica-Bold', marginRight: 4, width: 210 }}>
          Eligible amount of gift for tax purposes:
        </Text>
        <Text style={{ fontSize: 14, marginTop: -2 }}>{formatCurrency(receiptEligible)}</Text>
      </View>
    </View>
  );
}
