import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { TRenderGroupVariables } from '@typedefs/donation';

const styles = StyleSheet.create({
  row: { display: 'flex', flexDirection: 'row', justifyContent: 'space-between' },
  bold: { fontFamily: 'Helvetica-Bold' },
});

// ----------------------------------------------------------------------
type Props = {
  readonly groupVars: TRenderGroupVariables;
  readonly year: number;
};

// ----------------------------------------------------------------------
export default function ReceiptInfo({
  groupVars,
  year,
}: Props) {
  const {
    receiptNumberWithYear,
    receiptEligible,
    receiptEligibleCurrency,
    issuedOn,
    donationReceivedOn,
    isSingleDonation
  } = groupVars;

  return (
    <View>
      <View style={styles.row}>
        <Text style={styles.bold}>Receipt No.</Text>
        <Text>{receiptNumberWithYear}</Text>
      </View>

      <View style={styles.row}>
        <Text style={styles.bold}>Issued on:</Text>
        <Text>{issuedOn}</Text>
      </View>

      {receiptEligible ? (
        <View style={styles.row}>
          <Text style={{ fontFamily: 'Helvetica-Bold' }}>Total:</Text>
          <Text>{receiptEligibleCurrency}</Text>
        </View>
      ) : null}

      {isSingleDonation ? (
        <View style={styles.row}>
          <Text style={styles.bold}>Donation received:</Text>
          <Text>{donationReceivedOn}</Text>
        </View>
      ) : (
        <View style={styles.row}>
          <Text style={styles.bold}>Year received:</Text>
          <Text>{year}</Text>
        </View>
      )}
    </View>
  );
}
