import { wrap } from 'comlink';
import type { Remote } from 'comlink';
import type { ReceiptPDFWorkerType } from '@pages/receipts/ReceiptPDFDocument/receiptPdf.worker';
import Worker from '@pages/receipts/ReceiptPDFDocument/receiptPdf.worker?worker';

let pdfWorkerInstance: Remote<ReceiptPDFWorkerType> | null = null;

export const getReceiptPdfWorker = () => {
  if (!pdfWorkerInstance) {
      const worker = new Worker();
      pdfWorkerInstance = wrap<ReceiptPDFWorkerType>(worker);
  }
  return pdfWorkerInstance;
};