import { View, Text } from '@react-pdf/renderer';
import { TRenderGroupVariables } from '@typedefs/donation';
import { formatCurrency } from '@utils/numeralFormat';

// ----------------------------------------------------------------------
type Props = {
  readonly groupVars: TRenderGroupVariables;
  readonly year: number;
};
// ----------------------------------------------------------------------
export default function ReceiptBody({ groupVars, year }: Props) {
  const { receiptTotal } = groupVars;

  return (
    <View>
      <Text>Official {year} Receipt for Income Tax Purposes</Text>

      <View style={{ display: 'flex', flexDirection: 'row', marginTop: 16 }}>
        <Text style={{ fontFamily: 'Helvetica-Bold', marginRight: 4 }}>
          Thank you for your contribution of:
        </Text>
        <Text style={{ fontSize: 14, marginTop: -2 }}>{formatCurrency(receiptTotal)}</Text>
      </View>
    </View>
  );
}
