import { Meta } from '../index';
export * from './requests';

// ----------------------------------------------------------------------
export type Receipt = {
  readonly id: string;
  readonly _meta: Meta;

  readonly orgId: string;
  readonly donorId: string;
  readonly date: string | Date; // should match latest issue action
  readonly number: number;
  readonly year: number;

  // receipt can be invalidated in 2 ways
  // 1. donation, that has been issued in a receipt has been corrected
  // 2. re-issue entire receipt (without donation corrections)
  // -> receipt has to be re-issued with at minimum all donations, but more can be added
  readonly invalidated?: boolean; // shorthand prop if there is ReceiptAction correction
  readonly replacedById?: string; // receipt id that is replacing current receipt
  readonly replacesId?: string; // receipt id from old invalidated receipt

  readonly actions: ReceiptAction[];
};

export type ReceiptAction = {
  readonly userId: string;
  readonly createdAt?: string;
  readonly type: ReceiptActionType;
  readonly context?: { [key: string]: any };
};

export type ReceiptPartition = {
  donorId: string;
  donationIds: string[];
};

export type CreateReceiptForDonor = {
  userId: string;
  actionType: ReceiptActionType;
  date: string | Date;
  year: number;
  orgId: string;
  donorId: string;
  donationIds: string[];
};

export type ReceiptActionsForReceipt = {
  receipt?: Receipt;
  actionType: ReceiptActionType;
  userId: string;
  context?: { [key: string]: any };
};

export enum ReceiptActionType {
  // issue actions
  email = 'email',
  print = 'print',

  // reissue states
  replaced = 'replaced',
  corrected = 'corrected',
  replacement = 'replacement',
  correction = 'correction',

  // receipt (or under the hood) actions
  donationsAdded = 'donations_added', // donations added to the receipt (in re-issue flow)
  donationCorrected = 'donation_corrected', // donation under receipt changed, so we "invalidate" receipt
  donationDeleted = 'donation_deleted', // donation under receipt deleted, so we "invalidate" receipt
}

export type FailedEmails = { to: string; error: string }[];
