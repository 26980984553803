import { View, StyleSheet } from '@react-pdf/renderer';
import { TReceiptRenderGroup, TReceiptVariables } from '@typedefs/donation';

import ReceiptHeader from '../components/Header';
import ReceiptDonorInfo from '../components/DonorInfo';
import ReceiptSignature from '../components/Signature';
import ReceiptDonationsTable from '../components/DonationsTable';

import ReceiptBody from './Body';
import ReceiptInfo from './ReceiptInfo';
import ReceiptFootnote from './Footnote';
import { ReceiptOptions } from '@/schemas';
import ReceiptSummaryTable from '../components/SummaryTable';
import { Category, PaymentMethod } from '@shared/types';

const styles = StyleSheet.create({
  body: { display: 'flex', flexDirection: 'row' },
  bodyLeft: { flex: '1 1 auto', display: 'flex', rowGap: 16 },
  bodyRight: { width: '38%', display: 'flex', rowGap: 32 },
});

// ----------------------------------------------------------------------
type Props = {
  vars: TReceiptVariables;
  group: TReceiptRenderGroup;
  categories: Category.Category[];
  paymentMethods: PaymentMethod.PaymentMethodsWithNameAndType[];
  options: ReceiptOptions;
  year: number;
};
// ----------------------------------------------------------------------
export default function ReceiptUS({ vars, group, categories, paymentMethods, options, year }: Props) {
  const { groupVars } = group;

  return (
    <View>
      <ReceiptHeader vars={vars} />

      <View style={styles.body}>
        <View style={styles.bodyLeft}>
          <ReceiptBody
            groupVars={groupVars}
            year={year}
          />
          
          <ReceiptDonorInfo vars={vars} />
        </View>

        <View style={styles.bodyRight}>
          <ReceiptInfo
            vars={vars}
            groupVars={groupVars}
            receiptNumber={group.number}
            receiptDate={group.date}
            year={year}
            type={group.type}
          />
          <ReceiptSignature vars={vars} />
        </View>
      </View>

      <ReceiptFootnote vars={vars} />

      {options.includeDetails ? (
        <ReceiptDonationsTable
          vars={vars}
          donations={group.donations}
          categories={categories}
          paymentMethods={paymentMethods}
          showDescription={options.showDescription}
        />
      ) : null}
      {options.includeSummary ? (
        <ReceiptSummaryTable
          donations={group.donations}
          categories={categories}
        />
      ) : null}
    </View>
  );
}
