import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { Alert, Stack, Button, Grid, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { intersection, isEqual, isNumber, reduce, round } from 'lodash';

import * as analytics from '@fire/analytics';
import PATHS from '@routes/paths';
import { Category, Donation, Organization, PaymentMethod } from '@shared/types';
import useOrg from '@hooks/useOrg';
import useDonation from '@hooks/useDonation';
import useNavigate from '@hooks/useNavigate';

import { donationSchemaResolver, DonationSchemaForm } from '@/schemas';
import Dialog from '@components/Dialog';
import DonationForm from './Form';
import AdditionalForm from './AdditionalForm';
import ConfirmDialog from '@components/ConfirmDialog';
import ConflictDialog from '@components/ConflictDialog';
import useRole from '@hooks/useRole';
import useFormat from '@hooks/useFormat';

// ----------------------------------------------------------------------
type Props = { context: 'donors' | 'donations' | 'issue' | 'reissue' };
// ----------------------------------------------------------------------
export default function DonationDialog({ context = 'donors' }: Props) {
  const params = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { org } = useOrg();
  const { hasAccess } = useRole();
  const { fDateToISO } = useFormat();
  const {
    getCategoryById,
    getDonorById,
    getDonationById,
    createDonation,
    updateDonation,
    getTagsFromIds,
    getDonationReceipt,
    getDonationCategory,
    getDonationPaymentMethod,
    getPaymentMethodById,
    deleteDonation,
  } = useDonation();
  const [isCorrecting, setCorrecting] = useState(false);
  const [isConfirmingCorrection, setConfirmingCorrection] = useState(false);
  const [isConfirmingDelete, setConfirmingDelete] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [isDeletingReceipted, setDeletingReceipted] = useState(false);
  const [isInConflict, setInConflict] = useState<string>();

  // --------------- defaults ---------------
  const canEdit = hasAccess([Organization.Role.editor, Organization.Role.contributor]);
  const donation = useMemo(
    () => getDonationById(params.donationId),
    [getDonationById, params.donationId]
  );
  const donor = useMemo(() => {
    if (context === 'donations' && donation) {
      return getDonorById(donation.donorId) || null;
    } else {
      return getDonorById(params.donorId) || null;
    }
  }, [getDonorById, context, params.donorId, donation]);
  const receipt = useMemo(() => getDonationReceipt(donation), [getDonationReceipt, donation]);
  useEffect(() => donation && analytics.donation.donationView(), [donation]);

  const defaultValues: DonationSchemaForm = useMemo(
    () => ({
      donor,
      amount: donation ? donation.amount : '',
      date: (donation?.date && new Date(donation.date)) || new Date(),
      category: getDonationCategory(donation) || getCategoryById(org?.defaultCategoryId) || null,
      notes: donation?.notes || '',
      paymentMethod:
        getDonationPaymentMethod(donation) ||
        (!donation && getPaymentMethodById(org?.defaultPaymentMethodId)) ||
        null,
      paymentInfo: donation?.paymentInfo || '',
      withAdvantage: donation?.withAdvantage || false,
      amountEligible: donation?.amountEligible || donation?.amount || 0,
      amountAdvantage: donation?.amountAdvantage || 0,
      advantageDescription: donation?.advantageDescription || '',
      appraiserName: donation?.appraiserName || '',
      appraiserAddress: donation?.appraiserAddress || '',
      nonReceiptable: donation ? donation.nonReceiptable || false : false,
      description: donation?.description || '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [donation, getTagsFromIds]
  );

  // --------------- form ---------------
  const methods = useForm<DonationSchemaForm>({
    resolver: donationSchemaResolver(org?.address.country),
    defaultValues,
    mode: 'all',
    criteriaMode: 'all',
  });

  const {
    reset,
    watch,
    setError,
    setValue,
    setFocus,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting, dirtyFields },
  } = methods;
  const watchedYear = watch('date')?.getFullYear() || new Date().getFullYear();
  const watchedDonor = watch('donor');
  const watchedPaymentMethod = watch('paymentMethod');
  const watchedWithAdvantage = watch('withAdvantage');
  const watchedAmount = watch('amount');
  const watchedEligible = watch('amountEligible');
  const watchedNonReceiptable = watch('nonReceiptable');
  const isCA = org?.address.country === 'ca';

  // if changed fields are target to invalidate receipt - otherwise just update donation
  const changedFields = Object.keys(dirtyFields);
  const willInvalidateReceipt =
    receipt &&
    !!intersection(changedFields, [
      'date',
      'amount',
      'withAdvantage',
      'advantageDescription',
      'amountEligible',
      'paymentInfo',
      'category',
    ]).length;

  // --------------- routing ---------------
  const handleClose = useCallback(
    (reissue?: boolean, andNew?: boolean) => {
      if (andNew) {
        reset(defaultValues);
        return;
      }

      if (reissue && receipt) {
        navigate(PATHS.org.receipts.reissue.root, { receiptId: receipt.id });
        return;
      }

      if (context === 'donors') {
        if (params.donorId) {
          navigate(PATHS.org.donors.donor.edit);
        } else {
          navigate(PATHS.org.donors.root);
        }
      } else if (context === 'donations') {
        navigate(PATHS.org.donations.root);
      } else {
        navigate(-1);
      }
    },
    [navigate, reset, defaultValues, context, params, receipt]
  );

  // --------------- effects ---------------
  useEffect(() => {
    if (isDirty && Object.keys(dirtyFields).length && !isSubmitting) {
      const updateByUser = org?.users[donation?._meta.updatedBy as string]?.displayName;
      setInConflict(
        `${updateByUser} updated this donation. To avoid data loss, we need to reset this form.`
      );
    } else {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, defaultValues]);

  useEffect(() => {
    const firstError = Object.keys(errors)[0];

    if (firstError) {
      setFocus(firstError as any);
    }
  }, [errors, setFocus]);

  // update amount advantage based on amount - eligible
  useEffect(() => {
    const localAmount = isNumber(watchedAmount) ? watchedAmount : parseFloat(watchedAmount || '0');
    const stringAmount = String(watchedAmount);
    if (stringAmount.indexOf('.') !== -1 && stringAmount.split('.')[1].length > 2) {
      setValue('amount', parseFloat(stringAmount.slice(0, -1)));
    }

    if (watchedWithAdvantage) {
      const advantage = round(localAmount - (watchedEligible || localAmount), 2);
      setValue('amountAdvantage', advantage < 0 ? 0 : advantage);
    } else {
      setValue('amountAdvantage', 0);
      setValue('amountEligible', localAmount);
    }
  }, [setValue, watchedWithAdvantage, watchedAmount, watchedEligible]);

  // DC: I think we don't need this because we aren't forcing donations to have nonReceiptable
  // checked if the donor has it checked.
  // useEffect(() => {
  //   if (!donation && watchedDonor) {
  //     setValue('nonReceiptable', watchedDonor.nonReceiptable || false, { shouldDirty: false });
  //   }
  // }, [donation, watchedDonor, setValue]);

  // --------------- actions ---------------
  const handleNoOp = (e: React.MouseEvent<HTMLButtonElement>, action: VoidFunction) => {
    e.preventDefault();
    e.stopPropagation();
    action();
  };

  const handleFormReset = useCallback(() => {
    reset(defaultValues);
    setInConflict(undefined);
  }, [reset, defaultValues]);

  const handleCreateDonation = useCallback(
    async (data: DonationSchemaForm, orgId: string, closureClose: VoidFunction) => {
      if (!data.date) {
        setError('afterSubmit', { message: 'Missing date' });
        return;
      }
      // if (!data.paymentMethod) {
      //   setError('afterSubmit', { message: 'Missing payment method' });
      //   return;
      // }
      if (!data.category) {
        setError('afterSubmit', { message: 'Missing category' });
        return;
      }
      if (!isNumber(data.amount)) {
        setError('afterSubmit', { message: 'Missing amount' });
        return;
      }

      try {
        await createDonation({
          orgId,
          date: fDateToISO(data.date),
          donorId: data.donor!.id,
          amount: data.amount,
          categoryId: data.category.id,
          description: data.description,
          notes: data.notes,
          ...(data.paymentMethod?.id && { paymentMethodId: data.paymentMethod.id }),
          paymentInfo: data.paymentInfo,
          withAdvantage: data.withAdvantage,
          ...(data.withAdvantage && {
            amountEligible: data.amountEligible,
            amountAdvantage: data.amountAdvantage,
            advantageDescription: data.advantageDescription,
          }),
          ...(data.appraiserName && { appraiserName: data.appraiserName }),
          ...(data.appraiserAddress && { appraiserAddress: data.appraiserAddress }),
          nonReceiptable: data.nonReceiptable,
          receiptIds: [],
          corrections: [],
        });
        enqueueSnackbar('Donation created!');
        closureClose();
      } catch (error) {
        setError('afterSubmit', { ...error, message: error.message });
      }
    },
    [enqueueSnackbar, fDateToISO, setError, createDonation]
  );

  const handleUpdateDonation = useCallback(
    async (data: DonationSchemaForm, donation: Donation.Donation, closureClose: VoidFunction) => {
      setConfirmingCorrection(false);

      // NOTE: form always gives all fields back, changed or in initial state
      // we verify if they have changed and pull only those
      const update = {
        ...reduce(
          data,
          function (result, value, key) {
            switch (key) {
              case 'date':
                return isEqual((value as Date)?.toJSON(), donation[key]) ||
                  isEqual(fDateToISO(value as Date), donation[key])
                  ? result
                  : { ...result, [key]: fDateToISO(value as Date) };
              case 'paymentMethod':
                return isEqual((value as PaymentMethod.PaymentMethod)?.id, donation.paymentMethodId)
                  ? result
                  : {
                      ...result,
                      paymentMethodId: (value as PaymentMethod.PaymentMethod)?.id,
                    };
              case 'category':
                return isEqual((value as Category.Category).id, donation.categoryId)
                  ? result
                  : {
                      ...result,
                      categoryId: (value as Category.Category)?.id,
                    };
              case 'donor':
                // NOTE: donor can't be changed on the donation!
                return result;
              default:
                return isEqual(value, (donation as any)[key])
                  ? result
                  : { ...result, [key]: value };
            }
          },
          {}
        ),
        orgId: org?.id,
        receiptIds: willInvalidateReceipt ? donation?.receiptIds : undefined,
      };

      try {
        await updateDonation({ donationId: donation.id, update });
        enqueueSnackbar('Donation updated');
        closureClose();
      } catch (error) {
        setError('afterSubmit', { ...error, message: error.message });
      }
    },
    [enqueueSnackbar, fDateToISO, setError, updateDonation, org?.id, willInvalidateReceipt]
  );

  const onSubmit = useCallback(
    async (data: DonationSchemaForm, reissue?: boolean, andNew?: boolean) => {
      if (!org) {
        setError('afterSubmit', { message: 'Missing organization' });
        return;
      }
      if (!canEdit) {
        setError('afterSubmit', { message: 'You lack editing permissions' });
        return;
      }

      const closureClose = () => handleClose(reissue, andNew);

      if (donation) {
        await handleUpdateDonation(data, donation, closureClose);
      } else {
        await handleCreateDonation(data, org.id, closureClose);
      }
    },
    [handleCreateDonation, handleUpdateDonation, setError, handleClose, org, canEdit, donation]
  );

  const handleSave = useCallback(
    (data: DonationSchemaForm, andNew?: boolean) => onSubmit(data, false, andNew),
    [onSubmit]
  );

  const handleConfirmAndReissue = useCallback(
    async (data: DonationSchemaForm) => {
      await onSubmit(data, true);
    },
    [onSubmit]
  );

  const handleDelete = useCallback(
    async (reissue: boolean) => {
      if (!canEdit) {
        setError('afterSubmit', { message: 'You lack permissions!' });
        return;
      }
      if (!org) {
        setError('afterSubmit', { message: '[internal] Missing organization!' });
        return;
      }
      if (!donation) {
        setError('afterSubmit', { message: 'Missing donation to delete!' });
        return;
      }
      if (!isDeletingReceipted && donation.receiptIds.length > 0) {
        setError('afterSubmit', { message: 'Donation has receipt issued!' });
        return;
      }

      setDeleting(true);
      try {
        await deleteDonation({
          orgId: org.id,
          donationId: donation.id,
          canUpdateReceipt: isDeletingReceipted,
        });
      } catch (e) {
        setError('afterSubmit', { message: e.message });
      }
      setDeleting(false);
      handleClose(reissue);
    },
    [canEdit, org, donation, isDeletingReceipted, handleClose, setError, deleteDonation]
  );

  const renderActions = useMemo(() => {
    if (canEdit) {
      if (receipt) {
        if (isCorrecting) {
          // edits made to columns that would change the receipt
          return (
            <Stack direction="row" justifyContent="flex-end" width="100%">
              <LoadingButton
                type={willInvalidateReceipt ? 'button' : 'submit'}
                onClick={
                  willInvalidateReceipt
                    ? () => setConfirmingCorrection(true)
                    : handleSubmit((d) => handleSave(d))
                }
                color={willInvalidateReceipt ? 'error' : 'primary'}
                variant="contained"
                loading={isSubmitting}
              >
                {`Save ${willInvalidateReceipt ? 'and Correct Receipt' : ''}`}
              </LoadingButton>
            </Stack>
          );
        } else {
          // not correcting
          return (
            <Stack direction="row" justifyContent="space-between" width="100%">
              <Stack direction="row">
                <LoadingButton
                  type="button"
                  color="error"
                  onClick={(e) => handleNoOp(e, () => setCorrecting(true))}
                >
                  Edit Receipted Donation
                </LoadingButton>
                <LoadingButton
                  type="button"
                  color="error"
                  onClick={(e) => handleNoOp(e, () => setDeletingReceipted(true))}
                >
                  Delete Receipted Donation
                </LoadingButton>
              </Stack>
              <Button onClick={() => handleClose()}>Close</Button>
            </Stack>
          );
        }
      } else {
        // no receipt
        return (
          <Stack direction="row" justifyContent="space-between" width="100%">
            {!!donation && donation.receiptIds.length === 0 ? (
              <>
                <ConfirmDialog
                  open={isConfirmingDelete}
                  onClose={() => setConfirmingDelete(false)}
                  onConfirm={() => handleDelete(false)}
                  loading={isDeleting}
                />
                <LoadingButton
                  color="error"
                  onClick={() => setConfirmingDelete(true)}
                  loading={isDeleting}
                >
                  Delete Donation
                </LoadingButton>
              </>
            ) : (
              <Box />
            )}

            <Stack direction="row" spacing={2}>
              {!donation && (
                <LoadingButton
                  size="large"
                  variant="outlined"
                  loading={isSubmitting}
                  onClick={handleSubmit((d) => handleSave(d, true))}
                >
                  Create and New
                </LoadingButton>
              )}
              <LoadingButton
                type="submit"
                size="large"
                variant="contained"
                loading={isSubmitting}
                onClick={handleSubmit((d) => handleSave(d))}
              >
                {!!donation ? 'Save' : 'Create'}
              </LoadingButton>
            </Stack>
          </Stack>
        );
      }
    }

    // get here if can't edit
    return <Button onClick={() => handleClose()}>Close</Button>;
  }, [
    canEdit,
    isSubmitting,
    handleClose,
    handleSubmit,
    handleSave,
    receipt,
    isCorrecting,
    willInvalidateReceipt,
    setConfirmingDelete,
    handleDelete,
    donation,
    isDeleting,
    isConfirmingDelete,
  ]);

  const renderTitle = useMemo(() => {
    if (receipt) {
      // if (donation?.receipt.invalidated) {
      //   return 'Donation - invalidated'
      // }
      return 'Donation';
    } else if (!!donation) {
      return 'Edit donation';
    } else if (!!donor) {
      return `Create donation for ${donor.firstName + ' ' + donor.lastName}`;
    }
    return 'Create donation';
  }, [donor, donation, receipt]);

  const formDisabled = ((!!receipt || !canEdit) && !isCorrecting) || isSubmitting;

  return (
    <Dialog
      title={renderTitle}
      onClose={() => handleClose()}
      maxWidth="lg"
      isDirty={isDirty && Object.keys(dirtyFields).length > 0}
      isLoading={isSubmitting}
      methods={methods}
      actions={renderActions}
    >
      <ConflictDialog onConfirm={handleFormReset} contentText={isInConflict} />
      <ConfirmDialog
        open={isConfirmingCorrection}
        onClose={() => setConfirmingCorrection(false)}
        onConfirm={handleSubmit((d) => handleSave(d))}
        loading={isSubmitting}
        additionalAction={
          <LoadingButton
            size="large"
            variant="contained"
            loading={isSubmitting}
            onClick={handleSubmit(handleConfirmAndReissue)}
          >
            Confirm Save and Reissue Receipt
          </LoadingButton>
        }
      />
      <ConfirmDialog
        open={isDeletingReceipted}
        onClose={() => setDeletingReceipted(false)}
        onConfirm={handleSubmit(() => handleDelete(false))}
        loading={isSubmitting}
        additionalAction={
          <LoadingButton
            size="large"
            variant="contained"
            loading={isSubmitting}
            onClick={handleSubmit(() => handleDelete(true))}
          >
            Confirm Delete and Reissue Receipt
          </LoadingButton>
        }
      />

      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <DonationForm
            withAdvantage={watchedWithAdvantage}
            donorPredefined={!!donor}
            formDisabled={formDisabled}
            isCA={isCA}
            paymentMethodType={watchedPaymentMethod?.type}
            year={watchedYear}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <AdditionalForm
            donor={watchedDonor}
            donation={donation}
            receipt={receipt}
            watchedNonReceiptable={watchedNonReceiptable}
            formDisabled={formDisabled}
          />
        </Grid>
      </Grid>

      {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
    </Dialog>
  );
}
