import { PdfTableData, PdfTableHeaderProps } from '@components/pdf';
import { TColumn } from '@typedefs/app';
import { ReceiptListHeader, ReceiptListItem } from '@pages/receipts/List/config';

export const prepareReceiptTableData = (
  columns: TColumn<ReceiptListHeader>[],
  data: ReceiptListItem[]
): PdfTableData => {
  // Filter visible and non-internal columns
  const selectedColumns = columns.filter((c) => c.visible && !c.internal);

  // Calculate total width and width multiplier
  const totalWidth = selectedColumns.reduce((n, { pdfWidth }) => n + Number(pdfWidth || 10), 0);
  const widthMultiplier = 99 / totalWidth; // Safety margin to use 99% of the width
  let remainingWidth = 100;

  // Generate header for the PDF table
  const header: PdfTableHeaderProps[] = selectedColumns.map((c) => {
    const cellWidth = (c.pdfWidth || 10) * widthMultiplier;
    const width = remainingWidth - cellWidth >= 0 ? cellWidth : remainingWidth;
    remainingWidth -= width;

    return {
      name: c.label,
      width,
      align: c.align as any,
    };
  });

  // Generating rows for the PDF table
  const items = data.map((receiptItem) =>
    selectedColumns.map((column) => {
      switch (column.id) {
        case 'tags':
          return receiptItem._tags.map((t) => t.name).join(', ');
        case 'categories':
          return receiptItem._categories.map((c) => c.name).join(', ');
        default:
          // @ts-ignore-next-line
          return receiptItem[column.id] || '';
      }
    })
  );

  return { header, items };
};