import { View, Text, StyleSheet, Image } from '@react-pdf/renderer';
import { TReceiptVariables } from '@typedefs/donation';

const styles = StyleSheet.create({
  divider: {
    width: '100%',
    borderBottom: '1px solid #000',
    marginTop: 0,
    marginBottom: 4,
  },
  signature: {
    zIndex: -1,
    marginRight: 'auto',
    maxWidth: 160,
    maxHeight: 60,
  },
  spacer: {
    height: 60,
  },
});

// ----------------------------------------------------------------------
type Props = {
  vars: TReceiptVariables;
};
// ----------------------------------------------------------------------
export default function ReceiptSignature({ vars }: Props) {
  return (
    <View style={{ position: 'relative' }}>
      {vars['signatory.signature'] ? (
        <Image src={vars['signatory.signature'].url} style={styles.signature} />
      ) : (
        <View style={styles.spacer} />
      )}

      <View style={styles.divider} />

      <Text>
        {vars['signatory.name']}
        {vars['signatory.position'] ? `, ${vars['signatory.position']}` : ''}
      </Text>
      <Text style={{ marginTop: 8, fontFamily: 'Helvetica-Bold' }}>
        Thank you for your contribution.
      </Text>
    </View>
  );
}
