import { useCallback, useEffect, useState } from 'react';
import {
  last,
  groupBy,
  sortBy,
  map,
  findLastIndex,
  isArray,
  intersection,
  mapValues,
  max,
  uniq,
  filter,
} from 'lodash';
import { createSelector } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import { useDispatch, useSelector } from '@redux/store';
import {
  BankDeposit,
  CashCount,
  Donor,
  Donation,
  Pledge,
  Tag,
  Receipt,
  Organization,
  Country,
  Category,
  PaymentMethod,
  RecurringDonation,
  ImportData,
} from '@shared/types';
import * as slice from '@redux/slices/donation';
import { parseDateTimezone } from '@redux/slices/donation';
import {
  TDonationState,
  TDonorWithDonations,
  TPledgeWithActual,
  TReceiptDonorGroup,
  TReceiptRenderGroup,
  TReceiptVariables,
  TRenderGroupVariables,
  TGroupVars,
} from '@typedefs/donation';
import { MinAmountType, ReceiptDonorSchema } from '@/schemas';
import useFormat from '@hooks/useFormat';
import useOrg from '@hooks/useOrg';

const selectDonors = createSelector(
  [(s: TDonationState) => s.donors, (s, org?: Organization.Organization) => org],
  (donors, org) => donors.filter((d) => d.orgId === org?.id)
);
const selectDonations = createSelector(
  [(s: TDonationState) => s.donations, (s, org?: Organization.Organization) => org],
  (donations, org) => donations.filter((d) => d.orgId === org?.id)
);

const selectRecurringDonations = createSelector(
  [(s: TDonationState) => s.recurringDonations, (s, org?: Organization.Organization) => org],
  (recurringDonations, org) => recurringDonations.filter((d) => d.orgId === org?.id)
);

const selectDonorsWithDonations = createSelector(
  [(donors: Donor.Donor[]) => donors, (d, donations: Donation.Donation[]) => donations],
  (donors, donations) => {
    const donationsGrouped = groupBy(donations, 'donorId');
    return sortBy(
      donors.map((donor) => {
        const donations = sortBy(donationsGrouped[donor.id] || [], (d) =>
          new Date(d.date).getTime()
        );
        return {
          ...donor,
          donations,
          donationsTotal: donations.reduce((acc, d) => (acc += d.amount), 0),
          donationDate: last(donations)?.date || donor._meta.createdAt,
        };
      }),
      'lastName'
    );
  }
);
const selectPledges = createSelector(
  [(s: TDonationState) => s.pledges, (s, org?: Organization.Organization) => org],
  (pledges, org) => pledges.filter((d) => d.orgId === org?.id)
);
const selectCategories = createSelector(
  [(s: TDonationState) => s.categories, (s, org?: Organization.Organization) => org],
  (categories, org) => categories.filter((c) => c.orgId === org?.id)
);
const selectPaymentMethods = createSelector(
  [(s: TDonationState) => s.paymentMethods, (s, org?: Organization.Organization) => org],
  (paymentMethods, org) => paymentMethods.filter((c) => c.orgId === org?.id)
);
const selectTags = createSelector(
  [(s: TDonationState) => s.tags, (s, org?: Organization.Organization) => org],
  (tags, org) => tags.filter((tag) => tag.orgId === org?.id)
);
const selectReceipts = createSelector(
  [(s: TDonationState) => s.receipts, (s, org?: Organization.Organization) => org],
  (receipts, org) => receipts.filter((receipt) => receipt.orgId === org?.id)
);
const selectBankDeposits = createSelector(
  [(s: TDonationState) => s.bankDeposits, (s, org?: Organization.Organization) => org],
  (bankDeposits, org) => bankDeposits.filter((d) => d.orgId === org?.id)
);
const selectCashCounts = createSelector(
  [(s: TDonationState) => s.cashCounts, (s, org?: Organization.Organization) => org],
  (cashCounts, org) => cashCounts.filter((d) => d.orgId === org?.id)
);

// ----------------------------------------------------------------------
const useDonation = () => {
  const { org } = useOrg();
  const {
    fDate,
    fDateToISO,
    fLongDate,
    fFullName,
    fFullLegalName,
    fReversedName,
    fCurrency,
    fReceiptNumber,
    fAddress,
    fAddressLines,
    isDateBetween,
    fDateToDayStart,
    fDateToDayEnd,
  } = useFormat();
  const dispatch = useDispatch();

  // WARNING: state is full with data from all orgs, never use it without filtering!!!
  const _donationState = useSelector((s) => s.donation);
  // -------------------- donors --------------------
  const donors = selectDonors(_donationState, org);
  const donations = selectDonations(_donationState, org);
  const recurringDonations = selectRecurringDonations(_donationState, org);
  const donorsWithDonations: TDonorWithDonations[] = selectDonorsWithDonations(donors, donations);
  const pledges = selectPledges(_donationState, org);
  const categories = selectCategories(_donationState, org);
  const paymentMethods = selectPaymentMethods(_donationState, org);
  const tags = selectTags(_donationState, org);
  const receipts = selectReceipts(_donationState, org);
  const bankDeposits = selectBankDeposits(_donationState, org);
  const cashCounts = selectCashCounts(_donationState, org);
  
  const [orgLogo, setOrgLogo] = useState<{ url: string; width: number; height: number }>();
  useEffect(() => {
    if (org?.logo) {
      const img = new Image();
      img.onload = function () {
        setOrgLogo({ url: org.logo!, width: img.naturalWidth, height: img.naturalHeight });
      };
      img.src = org.logo;
    }
    setOrgLogo(undefined);
  }, [setOrgLogo, org?.logo]);

  const [signatorySignature, setSignatorySignature] = useState<{
    url: string;
    width: number;
    height: number;
  }>();
  useEffect(() => {
    if (org?.signatorySignature) {
      const img = new Image();
      img.onload = function () {
        setSignatorySignature({
          url: org.signatorySignature!,
          width: img.naturalWidth,
          height: img.naturalHeight,
        });
      };
      img.src = org.signatorySignature;
    }
    setSignatorySignature(undefined);
  }, [setOrgLogo, org?.signatorySignature]);

  // -------------------- selectors --------------------
  const getDonorById = useCallback(
    (donorId?: string) => donorsWithDonations.find((d) => d.id === donorId),
    [donorsWithDonations]
  );
  const getDonorDonations = useCallback(
    (donor?: Donor.Donor) => donations.filter((d) => d.donorId === donor?.id),
    [donations]
  );
  const getDonorPledges = useCallback(
    (donor?: Donor.Donor) => pledges.filter((p) => p.donorId === donor?.id),
    [pledges]
  );
  // If there is another donor with the same name as this donor, return a string for a
  // tooltip including the email address and postal address. Otherwise return ''.
  const getDupDonorTooltip = useCallback(
    (donor: Donor.Donor) => {
      const dup = donors.find(
        (d) => d.id !== donor.id && fReversedName(d) === fReversedName(donor)
      );
      return dup
        ? (!!donor.email ? [donor.email] : []).concat(fAddressLines(donor.address)).join(', ')
        : '';
    },
    [donors, fAddressLines, fReversedName]
  );
  const getDonorsForReceipt = useCallback(
    (
      allDonors: TDonorWithDonations[],
      tagIds: string[] = [],
      categoryIds: string[] = [],
      dateFrom?: Date,
      dateTo?: Date,
      actionType?: Receipt.ReceiptActionType,
      printAll?: boolean,
      minAmount?: number,
      minAmountType?: MinAmountType
    ) =>
      allDonors
        .filter((donor) => {
          const hasDonations = !!donor.donations.length;
          if (!hasDonations || donor.nonReceiptable) return false;

          const matchesTags = !tagIds.length || intersection(tagIds, donor.tagIds).length > 0;
          if (!matchesTags) return false;

          // by default match donor
          let matchesActionType = true;
          if (actionType) {
            if (actionType === Receipt.ReceiptActionType.email && !!donor.email) {
              // if action type is email, and user has email, add him
              matchesActionType = true;
            } else if (actionType === Receipt.ReceiptActionType.print) {
              if (printAll) {
                // if its print and its print all, all donors are included
                return true;
              } else {
                // if its print and not print all, only users without email are included
                matchesActionType = !donor.email;
              }
            } else {
              matchesActionType = false;
            }
          }

          return matchesActionType;
        })
        .map((donor): TDonorWithDonations => {
          const donations = donor.donations.filter((donation) => {
            const donationTime = new Date(donation.date).getTime();
            // NOTE: filter donations that don't have a receipt, are tax deductible,
            // have one of the filter categories, fit the given timeframe,
            // and match the minimum amount
            return (
              !donation.nonReceiptable &&
              !donation.receiptIds.length &&
              ((categoryIds.length && categoryIds.includes(donation.categoryId)) ||
                !categoryIds.length) &&
              (dateFrom?.getTime() || 0) <= donationTime &&
              donationTime <= (dateTo?.getTime() || Infinity) &&
              (!minAmount ||
                minAmountType !== MinAmountType.eachOnlyGE ||
                donation.amount >= minAmount)
            );
          });
          return {
            ...donor,
            donations,
            donationsTotal: donations.reduce((acc, d) => (acc += d.amount), 0),
          };
        })
        .filter(
          (d) =>
            (!minAmount ||
              minAmountType === MinAmountType.eachOnlyGE /* covered earlier under donations */ ||
              (minAmountType === MinAmountType.atLeastOneGE &&
                d.donations.some((d) => d.amount >= minAmount)) ||
              (minAmountType === MinAmountType.totalGE && d.donationsTotal >= minAmount)) &&
            d.donations.length
        ),
    []
  );
  const getDonorReceipts = useCallback(
    (donor?: Donor.Donor) => receipts.filter((r) => r.donorId === donor?.id),
    [receipts]
  );
  const getDonorReceiptIssues = useCallback(
    (donor: TDonorWithDonations, receiptActionType: Receipt.ReceiptActionType) => {
      const donorIssues: string[] = [];
      try {
        ReceiptDonorSchema(receiptActionType, org?.address.country).validateSync(donor, {
          abortEarly: false,
        });
      } catch (e) {
        donorIssues.push.apply(donorIssues, e.errors as string[]);
      }
      return donorIssues;
    },
    [org?.address.country]
  );
  const isMemberNumberInUse = useCallback(
    (year: number, number: number) =>
      donors.some((d) => Number(d.memberNumbers[year]) === Number(number)),
    [donors]
  );
  const nextMemberNumber = useCallback(
    (year: number) => {
      const numbers = donors
        .map((d) => d.memberNumbers[year])
        .filter(Boolean)
        .sort();
      let nextLowest = 1;

      for (let i = 1; i <= numbers.length + 1; i++) {
        if (!numbers.includes(i)) {
          nextLowest = i;
          break;
        }
      }
      const nextHighest = (max(numbers) || 0) + 1;
      return [nextLowest, nextHighest];
    },
    [donors]
  );
  const getDonationById = useCallback(
    (donationId?: string) => donations.find((d) => d.id === donationId),
    [donations]
  );
  const getDonationsByDate = useCallback(
    (searchDate: Date): Donation.Donation[] =>
      filter(donations, (d) =>
        isDateBetween(new Date(d.date), fDateToDayStart(searchDate), fDateToDayEnd(searchDate))
      ),
    [donations, isDateBetween, fDateToDayStart, fDateToDayEnd]
  );

  const getDonationDonor = useCallback(
    (donation?: Donation.Donation) => donors.find((d) => d.id === donation?.donorId),
    [donors]
  );
  const getDonationReceipt = useCallback(
    (donation?: Donation.Donation) => {
      const receiptId = last(donation?.receiptIds);
      return receipts.find((r) => r.id === receiptId);
    },
    [receipts]
  );
  const getPledgeById = useCallback(
    (pledgeId?: string) => pledges.find((d) => d.id === pledgeId),
    [pledges]
  );
  const getPledgeDonor = useCallback(
    (pledge?: Pledge.Pledge) => donors.find((d) => d.id === pledge?.donorId),
    [donors]
  );

  // For a given pledge, return its TPledgeWithActual, i.e. including the
  // total of the donations satisfying the plege.
  const getPledgeWithActual = useCallback(
    (pledge: Pledge.Pledge): TPledgeWithActual => {
      const pledgeDonations = donations.filter(
        (d) =>
          d.donorId === pledge.donorId &&
          isDateBetween(
            fDateToDayStart(parseDateTimezone(d.date)),
            fDateToDayStart(parseDateTimezone(pledge.dateStart)),
            fDateToDayEnd(parseDateTimezone(pledge.dateEnd))
          ) &&
          (!pledge.categoryId || pledge.categoryId === d.categoryId)
      ) as Donation.Donation[];
      return {
        ...pledge,
        donationsTotal: pledgeDonations.reduce((acc, d) => (acc += d.amount), 0),
      };
    },
    [donations, fDateToDayEnd, fDateToDayStart, isDateBetween]
  );

  // For a given donor, get their pledges as TPledgeWithActual, i.e. including the
  // total of the donations satisfying the pledge.
  // The pledges are sorted by dateStart.
  const getPledgesForDonor = useCallback(
    (donor: Donor.Donor): TPledgeWithActual[] => {
      const basePledges = sortBy(
        pledges.filter((p) => p.donorId === donor.id),
        'dateStart'
      );
      return basePledges.map((p) => getPledgeWithActual(p));
    },
    [getPledgeWithActual, pledges]
  );

  const getCategoryById = useCallback(
    (catId?: string) => categories.find((c) => c.id === catId),
    [categories]
  );
  const getCategoriesFromIds = useCallback(
    (categoryIds: string[]) =>
      categoryIds.map((id) => getCategoryById(id)).filter(Boolean) as Category.Category[],
    [getCategoryById]
  );
  const getDonationCategory = useCallback(
    (donation?: Donation.Donation) => getCategoryById(donation?.categoryId),
    [getCategoryById]
  );
  const getPledgeCategory = useCallback(
    (pledge?: Pledge.Pledge) => getCategoryById(pledge?.categoryId),
    [getCategoryById]
  );
  const categoryUseCountInDonations = useCallback(
    (catId: string | undefined) =>
      !catId ? 0 : donations.filter((d) => d.categoryId === catId).length,
    [donations]
  );
  const categoryUseCountInPledges = useCallback(
    (catId: string | undefined) =>
      !catId ? 0 : pledges.filter((d) => d.categoryId === catId).length,
    [pledges]
  );
  const getPaymentMethodById = useCallback(
    (paymentMethodId?: string) => paymentMethods.find((p) => p.id === paymentMethodId),
    [paymentMethods]
  );
  // Return the name of a payment method appended with its extra info (if any) if it's a
  // check or Gift in Kind.
  const getPaymentMethod = useCallback(
    (paymentMethodId?: string, paymentInfo?: string) => {
      const method = getPaymentMethodById(paymentMethodId);
      if (!method) return '';
      if (
        paymentMethodId &&
        [
          Donation.PaymentMethodInitialList.check as string,
          Donation.PaymentMethodInitialList.giftInKind as string,
        ].includes(method?.type)
      )
        return `${method.name} ${paymentInfo}`;
      else return method.name;
    },
    [getPaymentMethodById]
  );

  const getPaymentMethodsFromIds = useCallback(
    (paymentMethodIds: string[]) =>
      paymentMethodIds
        .map((id) => getPaymentMethodById(id))
        .filter(Boolean) as PaymentMethod.PaymentMethod[],
    [getPaymentMethodById]
  );
  const getDonationPaymentMethod = useCallback(
    (donation?: Donation.Donation) => getPaymentMethodById(donation?.paymentMethodId),
    [getPaymentMethodById]
  );
  const isPaymentMethodInUse = useCallback(
    (paymentMethodId: string) => donations.some((d) => d.paymentMethodId === paymentMethodId),
    [donations]
  );
  const getReceiptDonor = useCallback(
    (receipt?: Receipt.Receipt) => donors.find((d) => receipt?.donorId === d.id),
    [donors]
  );
  const getReceiptDonations = useCallback(
    (receipt?: Receipt.Receipt) =>
      donations.filter((d) => d.receiptIds.includes(receipt?.id || '')),
    [donations]
  );
  const getTagById = useCallback((tagId?: string) => tags.find((t) => t.id === tagId), [tags]);
  const getTagsFromIds = useCallback(
    (tagIds: string[]) => Tag.getTagsFromIds(tagIds, tags),
    [tags]
  );
  const isTagInUse = useCallback(
    (tagId: string) => donors.some((d) => d.tagIds.includes(tagId)),
    [donors]
  );
  const getReceiptById = useCallback(
    (receiptId?: string) => receipts.find((d) => d.id === receiptId),
    [receipts]
  );
  const getReceiptsByIds = useCallback(
    (receiptIds: string[]) => receipts.filter((d) => receiptIds.includes(d.id)),
    [receipts]
  );
  const getBankDepositById = useCallback(
    (bankDepositId?: string) => bankDeposits.find((d) => d.id === bankDepositId),
    [bankDeposits]
  );
  const getCashCountById = useCallback(
    (cashCountId?: string) => cashCounts.find((d) => d.id === cashCountId),
    [cashCounts]
  );

  // Get a sorted list of the years of donations, if necessary adding in the current year, as strings.
  const getYears = useCallback(
    () =>
      uniq([
        new Date().getFullYear().toString(),
        ...donations.map((d) => new Date(d.date).getFullYear().toString()),
      ])
        .sort()
        .reverse(),
    [donations]
  );

  // -------------------- helpers --------------------
  // variables for rendering PDFs (donor level)
  const getReceiptVariables = useCallback(
    (
      org: Organization.Organization,
      donor: TDonorWithDonations,
      donations?: Donation.Donation[]
    ): TReceiptVariables => ({
      'org.name': org.name,
      'org.address': {
        ...org.address,
        state: org.address.state?.toUpperCase() || '',
      },
      'org.fullAddress': fAddress(org.address, true),
      'org.number': org.registrationNumber,
      'org.phone': org.phone,
      'org.logo': orgLogo,
      'org.dateFormat': org.dateFormat,
      'org.religiousBenefit': org.religiousBenefit,
      'signatory.name': org.signatoryName,
      'signatory.position': org.signatoryPosition,
      'signatory.signature': signatorySignature,
      'signatory.email': org.emailSignature?.signatoryEmail,
      'donor.firstName': donor.firstName,
      'donor.middleName': donor.middleName,
      'donor.lastName': donor.lastName,
      'donor.fullName': fFullName(donor),
      'donor.fullLegalName': fFullLegalName(donor),
      'donor.reversedName': fReversedName(donor),
      'donor.email': donor.email || undefined,
      'donor.address': donor.address,
      'donor.fullAddress': fAddress(donor.address),
      'donations.totalByYear': mapValues(
        groupBy(donations || donor.donations, (d) => new Date(d.date).getFullYear()),
        (donations) => fCurrency(donations.reduce((acc, d) => acc + d.amount, 0))
      ),
      'donations.thisYearTotal': fCurrency(
        (donations || donor.donations)
          .filter((d) => new Date(d.date).getFullYear() === new Date().getFullYear())
          .reduce((acc, donation) => acc + donation.amount, 0)
      ),
      'donations.lifetimeTotal': fCurrency(
        (donations || donor.donations).reduce((acc, donation) => acc + donation.amount, 0)
      ),

      fDate,
      fLongDate,
      fCurrency,
      fReceiptNumber,
      getTagsFromIds,
      getCategoryById,
      getPaymentMethodById,
      getPaymentMethod,
    }),
    [
      fAddress,
      fFullName,
      fFullLegalName,
      fReversedName,
      fCurrency,
      fDate,
      fLongDate,
      fReceiptNumber,
      getTagsFromIds,
      getCategoryById,
      getPaymentMethodById,
      getPaymentMethod,
      orgLogo,
      signatorySignature,
    ]
  );

  // splits donations for each donor, into groups that will become a receipt
  // each partition is a single receipt
  // donor can have multiple partitions as donations are grouped on receipt differently
  const getReceiptPartitions = useCallback(
    (country: Country, donors: TDonorWithDonations[]): Receipt.ReceiptPartition[] => {
      const confirmedDonors = donors.filter((d) => !!d);
      const absentDonors = donors.filter((d) => !d);

      if (absentDonors.length > 0) {
        Sentry.captureMessage("There are some receipts without donors for the dataset", {
          extra: { donors },
        });
      }

      if (country === 'ca') {
        const partitions = {
          gik: [] as Donation.Donation[],
          withAdvantage: [] as Donation.Donation[],
          rest: [] as Donation.Donation[],
        };

        // CA has GiK payment type per donation in a separate receipt
        return (
          confirmedDonors
            .map(({ id, donations }) => {
              // clear the partitions for successive donors (otherwise same donation
              // can be in multiple receipts!)
              partitions.gik = [];
              partitions.withAdvantage = [];
              partitions.rest = [];

              // partition each donation to its respective group
              donations.forEach((d) => {
                const paymentMethod = getPaymentMethodById(d.paymentMethodId);

                if (paymentMethod?.type === Donation.PaymentMethodInitialList.giftInKind) {
                  partitions.gik.push(d);
                } else if (d.withAdvantage) {
                  partitions.withAdvantage.push(d);
                } else {
                  partitions.rest.push(d);
                }
              });

              // each gik or advantage donation should be its own receipt
              return [
                ...partitions.gik.map((d) => [d]),
                ...partitions.withAdvantage.map((d) => [d]),
                partitions.rest,
              ].map((donationGroup) => ({
                donorId: id,
                donationIds: donationGroup.map((d) => d.id),
              }));
            })
            .flat()
            // Make sure any receipts we pass back have donations in them!
            // Avoids bug with $0 normal receipt with no donations, when a donor
            // only has one or more GIK receipts.
            .filter((d) => d.donationIds.length > 0)
        );
      }

      // US has no split, all donations can go into a single receipt
      return donors.map(({ id, donations }) => ({
        donorId: id,
        donationIds: donations.map((d) => d.id),
      }));
    },
    [getPaymentMethodById]
  );

  const paymentMethodNames = paymentMethods.map((pm) => ({
    id: pm.id,
    name: pm.name,
    type: pm.type,
  }));

  const getDonationsInfo = useCallback(
    (
      donations: Donation.Donation[]
    ) => {
      const gikPayment = paymentMethodNames.find((pm) => pm.type === Donation.PaymentMethodInitialList.giftInKind);

      const gikDonation = donations.find((d) => d.paymentMethodId === gikPayment?.id);
      const advantageDonations = donations.filter((d) => d.withAdvantage && d.amountAdvantage);
      const advantageTotal = advantageDonations.reduce((acc, d) => acc + (d.amountAdvantage || 0), 0);
      const receiptTotal = donations.reduce((acc, d) => acc + d.amount, 0);
      const receiptEligible = receiptTotal - advantageTotal;
      const receiptEligibleCurrency = fCurrency(receiptEligible);

      return { gikDonation, advantageDonations, advantageTotal, receiptTotal, receiptEligible, receiptEligibleCurrency };
    }, [fCurrency, paymentMethodNames]);

  const getReceiptGroupVars = useCallback(
    (props: TGroupVars) => {
    const { receipt, donations, linkNumber, receiptNumber } = props;

    if (!receipt?.number && !receiptNumber) {
      throw new Error('Receipt or receipt number must be provided');
    }

    const { 
      gikDonation, advantageDonations, advantageTotal,
      receiptTotal, receiptEligible, receiptEligibleCurrency
    } = getDonationsInfo(donations);

    const year = donations[0] ? new Date(donations[0]?.date).getFullYear() : new Date().getFullYear();

    const groupVars: TRenderGroupVariables = {
      gikDonation,
      advantageDonations,
      advantageTotal,
      receiptTotal,
      receiptEligible,
      receiptEligibleCurrency,
      receiptNumberWithYear: fReceiptNumber(receiptNumber || receipt?.number as number, year),
      receiptLinkNumberWithYear: linkNumber ? fReceiptNumber(linkNumber as number, year) : '',
      paymentMethod: getPaymentMethod(donations[0]?.paymentMethodId, donations[0]?.paymentInfo),
      issuedOn: fLongDate(receipt?.date || fDateToISO()),
      donationReceivedOn: donations[0] ? fLongDate(donations[0]?.date) : '',
      isSingleDonation: donations.length === 1
    }

    return groupVars;
  }, [fReceiptNumber, fLongDate, fDateToISO, getPaymentMethod, getDonationsInfo]);

  // gets render receipts for receipt issue from partitions
  // this is grouped by donor, for each partition (receipt render data)
  const getReceiptIssueGroups = useCallback(
    (
      org: Organization.Organization,
      receiptPartitions: Receipt.ReceiptPartition[],
      receipts?: Receipt.Receipt[]
    ): TReceiptDonorGroup[] => {
      // in case no receipts are provided we mock it for preview
      const receiptingYear = receipts?.[0].year;
      const donationDate = getDonationById(receiptPartitions[0].donationIds[0])?.date;
      const donationYear = donationDate ? new Date(donationDate).getFullYear() : undefined;
      let mockReceiptNumber =
        org.receiptYear[receiptingYear || donationYear || new Date().getFullYear()] || 0;

      // all receipts are grouped by donor
      const donorPartitions = groupBy(receiptPartitions, 'donorId');

      // each donor gets all of its partitions (receipts) grouped into a single issue
      return map(donorPartitions, (partitions, donorId) => {
        const donor = getDonorById(donorId);
        if (!donor) {
          throw new Error(`Donor not found during partition rendering, ${donorId}`);
        }

        // NOTE: donor contains donations that were filtered in issue flow!
        const vars = getReceiptVariables(org, donor);
        const groups = partitions.map((p): TReceiptRenderGroup => {
          // filter donor donations belonging to this partition
          const donations = donor.donations.filter((d) => p.donationIds.includes(d.id));

          // receipt for this partition
          const receipt = receipts?.find((r) => r.id === last(donations[0]?.receiptIds));

          // NOTE: if no receipts are given, we mock it
          if (!receipt) {
            const newReceiptNumber = ++mockReceiptNumber;
            return {
              date: fDateToISO(),
              year: new Date(donations[0].date).getFullYear(),
              number: newReceiptNumber,
              donations,
              groupVars: getReceiptGroupVars({
                receipt,
                donations,
                receiptNumber: newReceiptNumber
              }),
            };
          }

          // receipt is done and ready to render
          return {
            date: receipt.date,
            year: receipt.year,
            number: receipt.number,
            donations,
            groupVars: getReceiptGroupVars({ receipt, donations })
          };
        });

        return { donorId, vars, groups, categories, paymentMethods: paymentMethodNames };
      });
    },
    [fDateToISO, getDonorById, getDonationById, getReceiptVariables, getReceiptGroupVars, categories, paymentMethodNames]
  );

  // based on current receipt, returns what will be the state of a new one
  // if type is not there, receipt will get updated - therefore this is only valid for
  // new state change
  const getReceiptReissueType = useCallback(
    (reissueReceipt: Receipt.Receipt, reissue: boolean, donationsAdded?: boolean) => {
      const isCA = org?.address.country === 'ca';

      // correction is if
      // - receipt is already inheriting another receipt that is corrected
      const inheritedCorrection = reissueReceipt.actions.some(
        (a) => a.type === Receipt.ReceiptActionType.correction
      );
      // - if any donation has been corrected while on existing receipt (that donor received)
      // - or if any donation in the receipt has been deleted
      // - or if on reissue donations are added
      const donationsCorrected =
        reissue &&
        (donationsAdded ||
          reissueReceipt.actions.some((a) =>
            [
              Receipt.ReceiptActionType.donationCorrected,
              Receipt.ReceiptActionType.donationDeleted,
            ].includes(a.type)
          ));
      const isCorrection = inheritedCorrection || donationsCorrected;

      // duplicate
      // - receipt is already inheriting another receipt that is duplicate
      const inheritedDuplicate = reissueReceipt.actions.some(
        (a) => a.type === Receipt.ReceiptActionType.replacement
      );
      // - when we are re-issuing the same receipt without any corrections on it, and
      // reissue flag is set to true (donor received previous receipt)
      const isDuplicate = inheritedDuplicate || reissue;

      const isNewReceipt = isCA && reissue;
      return {
        isNewReceipt,
        type: isCorrection
          ? Receipt.ReceiptActionType.correction
          : isDuplicate
            ? Receipt.ReceiptActionType.replacement
            : undefined,
      };
    },
    [org?.address.country]
  );

  const getReceiptReissueGroup = useCallback(
    (
      org: Organization.Organization,
      reissueReceipt: Receipt.Receipt,
      donations: Donation.Donation[], // all donations for this re-issue (receipt (if + added))
      reissue: boolean // reissueType - if donors received receipt or not (only for reissue flows)
    ): TReceiptDonorGroup => {
      const receiptingYear = reissueReceipt.year;
      let mockReceiptNumber = org.receiptYear[receiptingYear || new Date().getFullYear()] || 0;

      const donor = getDonorById(reissueReceipt.donorId);
      if (!donor) {
        throw new Error(`Donor not found during partition rendering, ${reissueReceipt.donorId}`);
      }

      const vars = getReceiptVariables(org, donor, donations);
      const donationsAdded = donations.some((d) => !d.receiptIds.includes(reissueReceipt.id));
      const { isNewReceipt, type } = getReceiptReissueType(reissueReceipt, reissue, donationsAdded);

      let linkNumber = reissueReceipt.number;
      // if Canada, link number is a previous receipt!
      if (
        org.address.country === 'ca' &&
        !isNewReceipt &&
        (type === Receipt.ReceiptActionType.correction ||
          type === Receipt.ReceiptActionType.replacement)
      ) {
        const linkedReceipt = getReceiptById(reissueReceipt.replacesId);
        linkNumber = linkedReceipt?.number || 0;
      }

      const renderGroup: TReceiptRenderGroup = {
        date: fDateToISO(),
        year: reissueReceipt.year,
        // any actions if donor received a receipt, has to be issued under a new receipt for CA
        number: isNewReceipt ? ++mockReceiptNumber : reissueReceipt.number,
        donations,
        linkNumber,
        type,
        groupVars: getReceiptGroupVars({
          receipt: reissueReceipt,
          donations,
          linkNumber
        }),
      };

      return {
        donorId: donor.id,
        vars,
        groups: [renderGroup],
        categories,
        paymentMethods: paymentMethodNames
      };
    },
    [fDateToISO, getDonorById, getReceiptById, getReceiptReissueType, getReceiptVariables, getReceiptGroupVars, categories, paymentMethodNames]
  );

  const getReceiptDonorGroup = useCallback(
    (
      org: Organization.Organization,
      donorReceipts: Receipt.Receipt | Receipt.Receipt[],
      donorDonations?: Donation.Donation[]
    ) => {
      const receipts = isArray(donorReceipts) ? donorReceipts : [donorReceipts];
      const { donorId } = receipts[0];
      const donor = getDonorById(donorId);
      if (!donor) {
        throw new Error(`Donor not found during partition rendering, ${donorId}`);
      }

      const getState = (r: Receipt.Receipt) => {
        const replacesReceipt = getReceiptById(r.replacesId);
        const replacedByReceipt = getReceiptById(r.replacedById);

        // once receipt has been corrected/replaced, it carries corrected forever (unless invalidated)
        const isCorrection = r.actions.some((a) => a.type === Receipt.ReceiptActionType.correction);
        const isReplacement = r.actions.some(
          (a) => a.type === Receipt.ReceiptActionType.replacement
        );
        // invalidated only IF invalidation happened, but there wasn't a correction action after it
        const lastInvalidation = findLastIndex(r.actions, (a) =>
          [
            Receipt.ReceiptActionType.donationCorrected,
            Receipt.ReceiptActionType.donationDeleted,
          ].includes(a.type)
        );
        const lastCorrection = findLastIndex(
          r.actions,
          (a) => a.type === Receipt.ReceiptActionType.correction
        );
        const isInvalidated = lastInvalidation > lastCorrection;

        if (replacedByReceipt) {
          // if receipt has been replaced by another
          // that means it has to be either a corrected or replaced (CA only)
          const isCorrected = r.actions.some((a) => a.type === Receipt.ReceiptActionType.corrected);
          return {
            linkNumber: replacedByReceipt.number,
            type: isCorrected
              ? Receipt.ReceiptActionType.corrected
              : Receipt.ReceiptActionType.replaced,
          };
        } else if (isInvalidated) {
          // NOTE: this shouldn't happen on issue/reissue, only if we allow receipt preview outside of the
          // issue/reissue flow
          return {
            type: r.actions[lastInvalidation].type, // was: Receipt.ReceiptActionType.donationCorrected,
          };
        } else if (isCorrection || isReplacement) {
          // this can be CA or US - US won't have a linkNumber since we don't reissue new receipt
          // for replacement/correction
          return {
            linkNumber: replacesReceipt?.number,
            type: isCorrection
              ? Receipt.ReceiptActionType.correction
              : Receipt.ReceiptActionType.replacement,
          };
        }

        return {};
      };

      const receiptIds = receipts.map((r) => r.id);
      const allReceiptedDonations = (donorDonations || donor.donations).filter(
        (d) => intersection(d.receiptIds, receiptIds).length
      );

      const renderGroups = receipts
        .map((r) => {
          const receiptDonations = allReceiptedDonations.filter((d) => d.receiptIds.includes(r.id));
          // There can be receipts with no donations, so we are not skipping them
          return {
            date: r.date,
            year: r.year,
            number: r.number,
            donations: receiptDonations,
            groupVars: getReceiptGroupVars({
              receipt: r,
              donations: receiptDonations,
              linkNumber: getState(r).linkNumber,
              receiptNumber: r.number,
            }),
            ...getState(r),
          };
        });

      const vars = getReceiptVariables(org, donor, allReceiptedDonations);
      return {
        donorId: donor.id,
        vars,
        groups: renderGroups,
        categories,
        paymentMethods: paymentMethodNames
      };
    },
    [getDonorById, getReceiptById, getReceiptVariables, getReceiptGroupVars, categories, paymentMethodNames]
  );

  const getRecurringDonationById = useCallback(
    (recurringDonationId?: string) => recurringDonations.find((d) => d.id === recurringDonationId),
    [recurringDonations]
  );

  // -------------------- hook --------------------
  return {
    // donor state
    donors,
    getDonorById,
    getDonorDonations,
    getDonorsForReceipt,
    getDonorReceipts,
    getYears,
    getDonorReceiptIssues,
    isMemberNumberInUse,
    nextMemberNumber,
    updateDonor: useCallback((p: Donor.UpdateReq) => dispatch(slice.updateDonor(p)), [dispatch]),
    assignMemberNumbers: useCallback(
      (p: Donor.AssignMemberNumbersReq) => dispatch(slice.assignMemberNumbers(p)),
      [dispatch]
    ),
    createDonor: useCallback((p: Donor.CreateReq) => dispatch(slice.createDonor(p)), [dispatch]),
    deleteDonor: useCallback((p: Donor.DeleteReq) => dispatch(slice.deleteDonor(p)), [dispatch]),

    // donation state
    donations,
    donorsWithDonations,
    getDonationById,
    getDupDonorTooltip,
    getDonationsByDate,
    getDonationDonor,
    getDonationReceipt,
    getDonationCategory,
    getDonationPaymentMethod,
    createDonation: useCallback(
      (p: Donation.CreateReq) => dispatch(slice.createDonation(p)),
      [dispatch]
    ),
    createBatchDonation: useCallback(
      (p: Donation.CreateBatchReq) => dispatch(slice.createBatchDonation(p)),
      [dispatch]
    ),

    updateDonation: useCallback(
      (p: Donation.UpdateReq) => dispatch(slice.updateDonation(p)),
      [dispatch]
    ),
    deleteDonation: useCallback(
      (p: Donation.DeleteReq) => dispatch(slice.deleteDonation(p)),
      [dispatch]
    ),

    // pledge state
    pledges,
    getDonorPledges,
    getPledgeById,
    getPledgeDonor,
    getPledgeCategory,
    getPledgeWithActual,
    getPledgesForDonor,
    createPledge: useCallback((p: Pledge.CreateReq) => dispatch(slice.createPledge(p)), [dispatch]),
    updatePledge: useCallback((p: Pledge.UpdateReq) => dispatch(slice.updatePledge(p)), [dispatch]),
    deletePledge: useCallback((p: Pledge.DeleteReq) => dispatch(slice.deletePledge(p)), [dispatch]),

    // receipt state
    receipts,
    getReceiptById,
    getReceiptsByIds,
    getReceiptDonor,
    getReceiptDonations,
    getReceiptVariables,
    getReceiptGroupVars,
    getReceiptPartitions,
    getReceiptDonorGroup,
    getReceiptReissueType,
    getReceiptIssueGroups,
    getReceiptReissueGroup,
    createReceipts: useCallback(
      (p: Receipt.CreateReceiptsReq) => dispatch(slice.createReceipt(p)),
      [dispatch]
    ),
    reissueReceipt: useCallback(
      (p: Receipt.ReissueReceiptReq) => dispatch(slice.reissueReceipt(p)),
      [dispatch]
    ),
    reissueReceipts: useCallback(
      (p: Receipt.ReissueReceiptsReq) => dispatch(slice.reissueReceipts(p)),
      [dispatch]
    ),
    sendReceiptTestEmail: useCallback(
      (p: Receipt.SendTestEmailReq) => dispatch(slice.sendReceiptTestEmail(p)),
      [dispatch]
    ),
    sendReceiptEmail: useCallback(
      (p: Receipt.SendEmailReq) => dispatch(slice.sendReceiptEmail(p)),
      [dispatch]
    ),

    // category state
    categories,
    getCategoryById,
    getCategoriesFromIds,
    categoryUseCountInDonations,
    categoryUseCountInPledges,
    createCategory: useCallback(
      (p: Category.CreateReq) => dispatch(slice.createCategory(p)),
      [dispatch]
    ),
    updateCategory: useCallback(
      (p: Category.UpdateReq) => dispatch(slice.updateCategory(p)),
      [dispatch]
    ),
    deleteCategory: useCallback(
      (p: Category.DeleteReq) => dispatch(slice.deleteCategory(p)),
      [dispatch]
    ),

    // paymentMethod state
    paymentMethods,
    paymentMethodNames,
    getPaymentMethodById,
    getPaymentMethod,
    getPaymentMethodsFromIds,
    isPaymentMethodInUse,
    createPaymentMethod: useCallback(
      (p: PaymentMethod.CreateReq) => dispatch(slice.createPaymentMethod(p)),
      [dispatch]
    ),
    updatePaymentMethod: useCallback(
      (p: PaymentMethod.UpdateReq) => dispatch(slice.updatePaymentMethod(p)),
      [dispatch]
    ),
    deletePaymentMethod: useCallback(
      (p: PaymentMethod.DeleteReq) => dispatch(slice.deletePaymentMethod(p)),
      [dispatch]
    ),

    // RecurringDonation state
    recurringDonations,
    getRecurringDonationById,
    createRecurringDonation: useCallback(
      (p: RecurringDonation.CreateReq) => dispatch(slice.createRecurringDonation(p)),
      [dispatch]
    ),
    updateRecurringDonation: useCallback(
      (p: RecurringDonation.UpdateReq) => dispatch(slice.updateRecurringDonation(p)),
      [dispatch]
    ),
    deleteRecurringDonation: useCallback(
      (p: RecurringDonation.DeleteReq) => dispatch(slice.deleteRecurringDonation(p)),
      [dispatch]
    ),

    // tag state
    tags,
    getTagById,
    getTagsFromIds,
    isTagInUse,
    createTag: useCallback((p: Tag.CreateReq) => dispatch(slice.createTag(p)), [dispatch]),
    updateTag: useCallback((p: Tag.UpdateReq) => dispatch(slice.updateTag(p)), [dispatch]),
    deleteTag: useCallback((p: Tag.DeleteReq) => dispatch(slice.deleteTag(p)), [dispatch]),

    // bankDeposit state
    bankDeposits,
    getBankDepositById,
    createBankDeposit: useCallback((p: BankDeposit.CreateReq) => dispatch(slice.createBankDeposit(p)), [dispatch]),
    updateBankDeposit: useCallback((p: BankDeposit.UpdateReq) => dispatch(slice.updateBankDeposit(p)), [dispatch]),
    deleteBankDeposit: useCallback((p: BankDeposit.DeleteReq) => dispatch(slice.deleteBankDeposit(p)), [dispatch]),

    // cashCount state
    cashCounts,
    getCashCountById,
    createCashCount: useCallback((p: CashCount.CreateReq) => dispatch(slice.createCashCount(p)), [dispatch]),
    updateCashCount: useCallback((p: CashCount.UpdateReq) => dispatch(slice.updateCashCount(p)), [dispatch]),
    deleteCashCount: useCallback((p: CashCount.DeleteReq) => dispatch(slice.deleteCashCount(p)), [dispatch]),

    // importData
    processImportData: useCallback(
      (p: ImportData.ImportDataReq) => dispatch(slice.processImportData(p)),
      [dispatch]
    ),
    // custom fields

    // Return the org's donor custom field based on an index from 1 to 6, or a fake field that isn't visible
    // if there is not one with that number.
    getDonorCustomField: useCallback(
      (n: number): Organization.DonorCustomField =>
        org?.donorCustomFields?.[n - 1] || { visible: false, name: '' },
      [org]
    ),
  };
};

export default useDonation;
