import { format, addMinutes } from 'date-fns';
import { isString } from 'lodash';
import { FormatDateList } from '@typedefs/org';

export const formatDate = (rawDate: string | Date, dateFormat: string | undefined) => {
  let date = new Date(rawDate);

  if (isString(rawDate)) {
    const offset = date.getTimezoneOffset();
    date = addMinutes(date, offset);
  }

  return format(date, dateFormat || FormatDateList[0].value);
}
