import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { Receipt } from '@shared/types';
import { TRenderGroupVariables, TReceiptVariables } from '@typedefs/donation';

const styles = StyleSheet.create({
  footnote: {
    marginTop: 16,
    marginBottom: 16,
    display: 'flex',
    flexDirection: 'row',
    columnGap: 8,
    fontSize: 10,
  },
});

// ----------------------------------------------------------------------
type Props = {
  groupVars: TRenderGroupVariables;
  vars: TReceiptVariables;
  year: number;
  type?: Receipt.ReceiptActionType;
};
// ----------------------------------------------------------------------
export default function ReceiptFootnote({ vars, groupVars, year, type }: Props) {
  const renderType = () => {
    const { receiptLinkNumberWithYear } = groupVars;
    if (!receiptLinkNumberWithYear || !type) return null;

    if (type === Receipt.ReceiptActionType.replacement) {
      return (
        <Text style={{ fontFamily: 'Helvetica-Bold' }}>
          This receipt cancels and replaces the original No. {receiptLinkNumberWithYear}
        </Text>
      );
    } else if (type === Receipt.ReceiptActionType.replaced) {
      return (
        <Text style={{ fontFamily: 'Helvetica-Bold' }}>
          This receipt is CANCELLED – it has been replaced by No.{' '}
          {receiptLinkNumberWithYear}
        </Text>
      );
    } else if (type === Receipt.ReceiptActionType.correction) {
      return (
        <Text style={{ fontFamily: 'Helvetica-Bold' }}>
          This receipt cancels, replaces and corrects the original No.{' '}
          {receiptLinkNumberWithYear}
        </Text>
      );
    } else if (type === Receipt.ReceiptActionType.corrected) {
      return (
        <Text style={{ fontFamily: 'Helvetica-Bold' }}>
          This receipt is CANCELLED – it has been replaced and corrected by No.{' '}
          {receiptLinkNumberWithYear}
        </Text>
      );
    }
  };

  return (
    <View style={styles.footnote}>
      <View style={{ width: '50%', display: 'flex', rowGap: 4 }}>
        {renderType()}
        <Text>Charity BN / Registration #: {vars['org.number']}</Text>
        <Text>Canada Revenue Agency: http://canada.ca/charities-giving</Text>
      </View>

      <View style={{ width: '50%', display: 'flex', rowGap: 4 }}>
        <Text>
          Location Issued: {vars['org.address'].city} {vars['org.address'].state}{' '}
          {vars['org.address'].postalCode}
        </Text>
        <Text>Official {year} receipt for Income Tax Purposes</Text>
      </View>
    </View>
  );
}
